<template>
  <div>
    <CustomBottomSheet
      refName="EducationalGroupUpdateWarning"
      size="md"
      :headerText="$t('EducationalGroups.updateWarning')"
      :headerIcon="alert"
    >
      <div class="row">
        <CustomCheckbox
          :className="'col-md-12'"
          :value="educationalGroupUpdateWarning.warningStatus1"
          v-on:changeValue="
            educationalGroupUpdateWarning.warningStatus1 = $event
          "
          :title="$t('EducationalGroupStudents.warningStatus1')"
        />
        <CustomCheckbox
          :className="'col-md-12'"
          :value="educationalGroupUpdateWarning.warningStatus2"
          v-on:changeValue="
            educationalGroupUpdateWarning.warningStatus2 = $event
          "
          :title="$t('EducationalGroupStudents.warningStatus2')"
        />
        <CustomCheckbox
          :className="'col-md-12'"
          :value="educationalGroupUpdateWarning.warningStatus3"
          v-on:changeValue="
            educationalGroupUpdateWarning.warningStatus3 = $event
          "
          :title="$t('EducationalGroupStudents.warningStatus3')"
        />
        <CustomCheckbox
          :className="'col-md-12'"
          :value="educationalGroupUpdateWarning.warningStatus4"
          v-on:changeValue="
            educationalGroupUpdateWarning.warningStatus4 = $event
          "
          :title="$t('EducationalGroupStudents.warningStatus4')"
        />
        <CustomCheckbox
          :className="'col-md-12'"
          :value="educationalGroupUpdateWarning.warningStatus5"
          v-on:changeValue="
            educationalGroupUpdateWarning.warningStatus5 = $event
          "
          :title="$t('EducationalGroupStudents.warningStatus5')"
        />
      </div>
      <div class="form-actions">
        <div class="icon-submit" @click.prevent="updateWarning()">
          <img src="@/assets/images/check-icon.svg" :title="$t('add')" />
        </div>
        <div
          @click.prevent="closeBottomSheet('EducationalGroupUpdateWarning')"
          class="icon-cancel"
        >
          <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
        </div>
      </div>
    </CustomBottomSheet>
  </div>
</template>

<script>
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
import CustomCheckbox from "./../../../../components/general/CustomCheckbox.vue";
import { STATUS } from "./../../../../utils/constants";
import { objectToFormData } from "./../../../../utils/functions";
import apiEducationalGroup from "./../../../../api/educational/educationalGroups";
import generalMixin from "./../../../../utils/generalMixin";
import alert from "@/assets/images/alert.svg";

export default {
  mixins: [generalMixin],
  components: {
    CustomBottomSheet,
    CustomCheckbox,
  },
  props: ["educationalGroupUpdateWarning"],
  data() {
    return {
      alert,
    };
  },
  methods: {
    async updateWarning() {
      this.isLoading = true;
      let formData = objectToFormData(this.educationalGroupUpdateWarning);
      try {
        const response = await apiEducationalGroup.updateWarning(formData);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.closeBottomSheet("EducationalGroupUpdateWarning");
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  created() {},
};
</script>
