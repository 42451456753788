<template>
  <div class="row custom-cards">
    <CustomCard
      v-for="(educationalGroupStudent, index) in educationalGroupStudentsData"
      :key="index"
      :className="'col-sm-6 col-md-4 col-lg-3'"
      :title="
        educationalGroupStudent.studentUserInfoDate
          ? educationalGroupStudent.studentUserInfoDate.userNameCurrent
          : ''
      "
      :description="
        educationalGroupStudent.studentUserInfoDate
          ? educationalGroupStudent.studentUserInfoDate.fullCode
          : ''
      "
      :imagePath="educationalGroupStudent.defaultImg"
      :defaultImg="defaultImg"
      :index="++index + filterData.currentIndex"
    >
      <li
        v-if="
          checkPrivilege(hasChangeStudentCertificateStatus()) &&
          educationalGroupStudent.certificateStatusToken ==
            CERTIFICATE_STATUS_TYPE.Ready
        "
      >
        <button
          @click="certificateExport(educationalGroupStudent)"
          :title="$t('general.certificateExport')"
        >
          <img src="@/assets/images/certificateExport.svg" />
        </button>
      </li>
      <li
        v-if="
          checkPrivilege(hasChangeStudentCertificateStatus()) &&
          educationalGroupStudent.certificateStatusToken ==
            CERTIFICATE_STATUS_TYPE.Export
        "
      >
        <button
          @click="certificateDeliver(educationalGroupStudent)"
          :title="$t('general.certificateDeliver')"
        >
          <img src="@/assets/images/certificateDeliver.svg" />
        </button>
      </li>
      <li>
        <button
          :title="$t('info')"
          @click="
            setEducationalGroupStudentData(educationalGroupStudent);
            openBottomSheet('EducationalGroupStudentInfo');
          "
        >
          <img src="@/assets/images/info.svg" />
        </button>
      </li>
      <li v-if="checkPrivilege(hasMediaPackageCodeSubscribe())">
        <button
          @click="
            fillDataToSubscribeInMediaPackage({
              userToken: educationalGroupStudent.studentUserInfoDate.userToken,
              joinInEducationalGroupToken:
                educationalGroupStudent.educationalGroupInfoData
                  .educationalGroupToken,
            });
            openBottomSheet('SubscribeInMediaPackage');
          "
          :title="$t('MediaPackageCodes.subscribe')"
        >
          <img src="@/assets/images/subscribeInPakage.svg" />
        </button>
      </li>
      <li v-if="checkPrivilege(hasMediaPackageCodeReport())">
        <button
          @click="
            setEducationalGroupStudentData(educationalGroupStudent);
            openBottomSheet('MediaPackageCodesFilter');
          "
          :title="$t('Reports.mediaPackageCodes')"
        >
          <img src="@/assets/images/report.svg" />
        </button>
      </li>
      <li v-if="checkPrivilege(hasEducationalGroupStudent())">
        <button
          @click="
            setEducationalGroupStudentData(educationalGroupStudent);
            openBottomSheet('StudentStatisticsReport');
          "
          :title="$t('Reports.studentStatistics')"
        >
          <img src="@/assets/images/statistics.svg" />
        </button>
      </li>

      <li>
        <button
          @click="
            setEducationalGroupStudentData(educationalGroupStudent);
            openBottomSheet('ActionsData');
          "
          :title="$t('actionsData.modelName')"
        >
          <img src="@/assets/images/actions-data.svg" />
        </button>
      </li>
      <li v-if="checkPrivilege(hasStudent())">
        <router-link
          :to="{
            name: 'Students',
            params: {
              userToken: educationalGroupStudent.studentUserToken,
            },
          }"
          :title="$t('students.data')"
        >
          <img src="@/assets/images/students.svg" />
        </router-link>
      </li>
      <li v-if="checkPrivilege(hasDebt())">
        <router-link
          :to="{
            name: 'Debts',
            params: {
              userToken: educationalGroupStudent.studentUserToken,
            },
          }"
          :title="$t('Debts.data')"
        >
          <img src="@/assets/images/debts.svg" />
        </router-link>
      </li>
      <li v-if="checkPrivilege(hasInstallment())">
        <router-link
          :to="{
            name: 'Installments',
            params: {
              userToken: educationalGroupStudent.studentUserToken,
            },
          }"
          :title="$t('Installments.data')"
        >
          <img src="@/assets/images/installments.svg" />
        </router-link>
      </li>
      <li v-if="checkPrivilege(hasInstallmentPayment())">
        <router-link
          :to="{
            name: 'InstallmentPayments',
            params: {
              userToken: educationalGroupStudent.studentUserToken,
            },
          }"
          :title="$t('InstallmentPayments.data')"
        >
          <img src="@/assets/images/installmentPayments.svg" />
        </router-link>
      </li>
      <li v-if="checkPrivilege(hasStudentScheduleExamTime())">
        <router-link
          :to="{
            name: 'StudentScheduleExamTimes',
            params: {
              userToken: educationalGroupStudent.studentUserToken,
              educationalGroupToken:
                educationalGroupStudent.educationalGroupToken,
            },
          }"
          :title="$t('StudentScheduleExamTimes.data')"
        >
          <img src="@/assets/images/StudentScheduleExamTimes.svg" />
        </router-link>
      </li>

      <li v-if="checkPrivilege(hasMonitorScoreExamAdd())">
        <button
          @click="
            setMonitorScoreExamData(educationalGroupStudent);
            openBottomSheet('MonitorScoreExamAdd');
          "
          :title="$t('MonitorScoreExams.add')"
        >
          <img src="@/assets/images/plus.svg" />
        </button>
      </li>

      <li v-if="checkPrivilege(hasUpdateWarning())">
        <button
          @click="
            setEducationalGroupUpdateWarningData(educationalGroupStudent);
            openBottomSheet('EducationalGroupUpdateWarning');
          "
          :title="$t('EducationalGroups.updateWarning')"
        >
          <img src="@/assets/images/alert.svg" />
        </button>
      </li>
      <li
        v-if="
          educationalGroupStudent.freezeStatusToken ==
            FREEZE_STATUS_TYPE.NotFreezed &&
          checkPrivilege(hasChangeStudentFreezeStatus())
        "
      >
        <button
          v-b-modal.EducationalGroupStudentFreeze
          @click="setEducationalGroupStudentData(educationalGroupStudent)"
          :title="$t('general.makeFreezed')"
        >
          <img src="@/assets/images/user-cancel.svg" />
        </button>
      </li>
      <li
        v-if="
          educationalGroupStudent.freezeStatusToken ==
            FREEZE_STATUS_TYPE.Freezed &&
          checkPrivilege(hasChangeStudentFreezeStatus())
        "
      >
        <button
          @click="makeNotFreezed(educationalGroupStudent)"
          :title="$t('general.makeNotFreezed')"
        >
          <img src="@/assets/images/user-ok.svg" />
        </button>
      </li>
    </CustomCard>
    <SubscribeInMediaPackage
      :subscribeInMediaPackage="subscribeInMediaPackageClass"
    />
  </div>
</template>

<script>
import {
  CERTIFICATE_STATUS_TYPE,
  FREEZE_STATUS_TYPE,
} from "./../../../../utils/constantLists";
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
} from "./../../../../utils/functions";
import {
  hasStudent,
  hasChangeStudentCertificateStatus,
  hasChangeStudentFreezeStatus,
  hasDebt,
  hasMediaPackageCodeSubscribe,
  hasMediaPackageCodeReport,
  hasEducationalGroupStudent,
  hasInstallment,
  hasInstallmentPayment,
  hasStudentScheduleExamTime,
  hasMonitorScoreExamAdd,
  hasUpdateWarning,
} from "./../../../../utils/privilegeHelper";
import CustomCard from "./../../../../components/general/CustomCard.vue";
import generalMixin from "./../../../../utils/generalMixin";
import subscribeInMediaPackageMixin from "./../../../../utils/subscribeInMediaPackageMixin";

export default {
  mixins: [generalMixin, subscribeInMediaPackageMixin],
  components: {
    CustomCard,
  },
  props: ["educationalGroupStudentsData", "filterData", "defaultImg"],
  data() {
    return {
      CERTIFICATE_STATUS_TYPE,
      FREEZE_STATUS_TYPE,
    };
  },
  methods: {
    setEducationalGroupStudentData(educationalGroupStudent) {
      this.$emit("setEducationalGroupStudentData", educationalGroupStudent);
    },
    setEducationalGroupUpdateWarningData(educationalGroupStudent) {
      this.$emit(
        "setEducationalGroupUpdateWarningData",
        educationalGroupStudent
      );
    },
    setMonitorScoreExamData(educationalGroupStudent) {
      let educationalGroupStudentTokens = [
        educationalGroupStudent.educationalGroupStudentToken,
      ];
      let monitorScoreStudentsDataHandler = [
        {
          educationalGroupStudentToken:
            educationalGroupStudent.educationalGroupStudentToken,
          studentScore: 0,
          student: {
            fullCode: educationalGroupStudent.studentUserInfoDate.fullCode,
            userNameCurrent:
              educationalGroupStudent.studentUserInfoDate.userNameCurrent,
          },
        },
      ];
      this.$emit("setMonitorScoreExamData", {
        ...educationalGroupStudent,
        userStudentInfoData: educationalGroupStudent.studentUserInfoDate,
        monitorScoreStudentsDataHandler,
        educationalGroupStudentTokens,
      });
      this.openBottomSheet("MonitorScoreExamAdd");
    },
    certificateExport(educationalGroupStudent) {
      this.$emit("certificateExport", educationalGroupStudent);
    },
    certificateDeliver(educationalGroupStudent) {
      this.$emit("certificateDeliver", educationalGroupStudent);
    },
    makeNotFreezed(educationalGroupStudent) {
      this.$emit("makeNotFreezed", educationalGroupStudent);
    },
    checkPrivilege,
    hasStudent,
    hasChangeStudentCertificateStatus,
    hasChangeStudentFreezeStatus,
    hasDebt,
    hasMediaPackageCodeSubscribe,
    hasMediaPackageCodeReport,
    hasEducationalGroupStudent,
    hasInstallment,
    hasInstallmentPayment,
    hasStudentScheduleExamTime,
    hasMonitorScoreExamAdd,
    hasUpdateWarning,
    isDataExist,
    fullPathFileFromServer,
  },
};
</script>
