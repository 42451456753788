<template>
  <div class="">
    <form autocomplete="off">
      <div class="row">
        <CustomFileInput
          :className="'col-12'"
          @changeValue="MonitorScoreExam.mediaFile = $event.file"
          :defaultImg="
            fullPathFileFromServer(
              MonitorScoreExam.mediaFilePath,
              MonitorScoreExam.defaultImg
            )
          "
          :deleteFileStatus="
            deleteFileStatus &&
            !MonitorScoreExam.mediaFileIsDefault &&
            checkPrivilege(hasMonitorScoreExamDeleteImage())
          "
          v-on:deleteFile="$emit('deleteFile')"
        />
      </div>

      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-4'"
            :id="`${id}-fullCode`"
            :value="MonitorScoreExam.fullCode"
            v-on:changeValue="MonitorScoreExam.fullCode = $event"
            :title="$t('MonitorScoreExams.code')"
            :imgName="'code.svg'"
          />
          <CustomSelectBox
            :className="'col-md-4'"
            :id="`${id}-examTypeToken`"
            :value="MonitorScoreExam.examTypeToken"
            :options="examTypeTokenOptions"
            v-on:changeValue="MonitorScoreExam.examTypeToken = $event"
            :title="$t('ConstantsListSelect.ExamTypes')"
            :imgName="'ExamTypes.svg'"
          />
          <customInputFloat
            :className="'col-md-4'"
            :id="`${id}-examFinalScore`"
            :value="MonitorScoreExam.examFinalScore"
            v-on:changeValue="MonitorScoreExam.examFinalScore = $event"
            :title="$t('MonitorScoreExams.finalScore')"
            :imgName="'number.svg'"
          />

          <CustomInput
            :className="'col-md-6'"
            :id="`${id}-examNameAr`"
            :value="MonitorScoreExam.examNameAr"
            v-on:changeValue="MonitorScoreExam.examNameAr = $event"
            :title="$t('MonitorScoreExams.nameAr')"
            :imgName="'MonitorScoreExams.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="`${id}-examNameEn`"
            :value="MonitorScoreExam.examNameEn"
            v-on:changeValue="MonitorScoreExam.examNameEn = $event"
            :title="$t('MonitorScoreExams.nameEn')"
            :imgName="'MonitorScoreExams.svg'"
          />

          <TextArea
            :className="'col-md-12'"
            :id="`${id}-examNameNotes`"
            :value="MonitorScoreExam.examNameNotes"
            v-on:changeValue="MonitorScoreExam.examNameNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />

          <div class="table-container">
            <table
              class="my-table"
              v-if="MonitorScoreExam.monitorScoreStudentsDataHandler.length > 0"
            >
              <thead>
                <tr>
                  <th rowspan="2">#</th>
                  <th rowspan="2">
                    <input
                      type="checkbox"
                      id="selectAll"
                      @click="selectAll()"
                      v-model="selectedAll"
                      class="checkbox"
                    />
                    {{ $t("general.all") }}
                  </th>
                  <th colspan="3">{{ $t("students.data") }}</th>
                </tr>
                <tr>
                  <th>{{ $t("general.code") }}</th>
                  <th>{{ $t("general.name") }}</th>
                  <th>{{ $t("MonitorScoreExams.studentScore") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(
                    item, index
                  ) in MonitorScoreExam.monitorScoreStudentsDataHandler"
                  :key="index"
                >
                  <td>{{ ++index }}</td>
                  <td>
                    <input
                      type="checkbox"
                      v-model="MonitorScoreExam.educationalGroupStudentTokens"
                      :value="item.educationalGroupStudentToken"
                      @click="select(item.educationalGroupStudentToken)"
                      class="checkbox"
                    />
                  </td>
                  <td>{{ isDataExist(item.student.fullCode) }}</td>
                  <td>{{ isDataExist(item.student.userNameCurrent) }}</td>

                  <td>
                    <customInputFloat
                      :className="'col-md-12'"
                      :withOutDesign="true"
                      :id="`${id}-studentScore`"
                      :value="item.studentScore"
                      v-on:changeValue="item.studentScore = $event"
                      :title="$t('MonitorScoreExams.studentScore')"
                      :imgName="'number.svg'"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="form-actions">
        <div class="icon-submit" @click.prevent="submitForm">
          <img src="@/assets/images/check-icon.svg" :title="submitName" />
        </div>
        <div
          @click.prevent
          class="icon-cancel"
          v-b-modal="`ConfirmCloseSheet-${bottomSheetName}`"
        >
          <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { fullPathFileFromServer } from "./../../../../utils/functions";
import CustomFileInput from "./../../../../components/general/CustomFileInput.vue";
import CustomInput from "./../../../../components/general/CustomInput.vue";
import TextArea from "./../../../../components/general/TextArea.vue";
import CustomSelectBox from "./../../../../components/general/CustomSelectBox.vue";
import { hasMonitorScoreExamDeleteImage } from "./../../../../utils/privilegeHelper";
import { getDialogOfExamTypes } from "./../../../../utils/dialogsOfConstantsLists";
import { checkPrivilege, isDataExist } from "./../../../../utils/functions";
import generalMixin from "./../../../../utils/generalMixin";
import CustomInputFloat from "../../../../components/general/CustomInputFloat.vue";

export default {
  mixins: [generalMixin],
  components: {
    CustomFileInput,
    CustomInput,
    TextArea,
    CustomSelectBox,
    CustomInputFloat,
  },
  data() {
    return {
      examTypeTokenOptions: getDialogOfExamTypes(),
      selectedAll: false,
    };
  },
  props: {
    MonitorScoreExam: {
      type: Object,
    },
    submitName: {
      type: String,
    },
    id: {
      type: String,
      default: "id",
    },
    opened: {
      type: Boolean,
      default: false,
    },
    deleteFileStatus: {
      type: Boolean,
      default: false,
    },
    bottomSheetName: {
      type: String,
      default: "",
    },
  },
  computed: {},
  watch: {
    opened: function (v) {
      if (v) {
        if (
          this.MonitorScoreExam.monitorScoreStudentsDataHandler.length ==
          this.MonitorScoreExam.educationalGroupStudentTokens.length
        ) {
          this.selectedAll = true;
        } else {
          this.selectedAll = false;
        }

        if (!this.MonitorScoreExam.addMany) this.selectedAll = true;
      }
    },
  },
  methods: {
    selectAll() {
      this.MonitorScoreExam.educationalGroupStudentTokens = [];
      this.selectedAll = !this.selectedAll;
      if (this.selectedAll) {
        this.MonitorScoreExam.monitorScoreStudentsDataHandler.forEach(
          (element) => {
            this.MonitorScoreExam.educationalGroupStudentTokens.push(
              element.educationalGroupStudentToken
            );
          }
        );
      }
    },
    select(token) {
      const indexItem =
        this.MonitorScoreExam.educationalGroupStudentTokens.indexOf(token);

      if (indexItem > -1) {
        this.MonitorScoreExam.educationalGroupStudentTokens.splice(
          indexItem,
          1
        );
      }
      setTimeout(() => {
        if (
          this.MonitorScoreExam.monitorScoreStudentsDataHandler.length ==
          this.MonitorScoreExam.educationalGroupStudentTokens.length
        ) {
          this.selectedAll = true;
        } else {
          this.selectedAll = false;
        }
      }, 1000);
    },

    async submitForm() {
      if (this.MonitorScoreExam.educationalGroupStudentTokens.length) {
        this.$emit("submitForm");
      } else {
        this.showMsg(this.$t("students.select"));
      }
    },
    checkPrivilege,
    isDataExist,
    hasMonitorScoreExamDeleteImage,
    fullPathFileFromServer,
  },
  created() {},
};
</script>
