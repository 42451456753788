import BaseEntityModel from "./../../general/BaseEntityModel";
import UserInfoData from "./../../general/UserCreatedData";
import EducationalGroupInfoData from "./../educationalGroups/EducationalGroupInfoData";
import EducationalCategoryInfoData from "./../educationalCategories/EducationalCategoryInfoData";
import defaultImg from "@/assets/images/MonitorScoreExams.svg";

export default class MonitorScoreExam extends BaseEntityModel {
  icon = defaultImg;
  defaultImg = defaultImg;
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue(addMany = false) {
    this.setInitialValueBaseEntityModel();
    this.mediaFile = "";
    this.monitorScoreExamToken = "";
    this.examNameCurrent = "";
    this.examNameAr = "";
    this.examNameEn = "";
    this.examNameUnd = "";
    this.examNameNotes = "";
    this.examFinalScore = 0;
    this.studentScore = 0;
    this.studentPercentage = 0;
    this.studentPercentageText = "";
    this.examTypeToken = "";
    this.examTypeNameCurrent = "";
    this.mediaFileTypeToken = "";
    this.mediaFileTypeNameCurrent = "";
    this.mediaFileName = "";
    this.mediaFilePath = "";
    this.mediaFileIsDefault = true;
    this.mediaFileSizeBytes = 0;
    this.educationalGroupStudentToken = "";
    this.userStudentInfoData = new UserInfoData();
    this.educationalGroupInfoData = new EducationalGroupInfoData();
    this.educationalCategoryData = new EducationalCategoryInfoData();
    this.educationalGroupStudentTokens = []; //list educationalGroupStudentTokens
    this.monitorScoreStudentsDataHandler = []; //list to handele
    this.monitorScoreStudentsData = []; //list to add
    this.addMany = addMany;
  }
  fillData(data) {
    if (data) {
      this.fillDataBaseEntityModel(data);
      this.mediaFile = "";
      this.monitorScoreExamToken = data.monitorScoreExamToken ?? "";
      this.examNameCurrent = data.examNameCurrent ?? "";
      this.examNameAr = data.examNameAr ?? "";
      this.examNameEn = data.examNameEn ?? "";
      this.examNameUnd = data.examNameUnd ?? "";
      this.examNameNotes = data.examNameNotes ?? "";
      this.examFinalScore = data.examFinalScore ?? 0;
      this.studentScore = data.studentScore ?? 0;
      this.studentPercentage = data.studentPercentage ?? 0;
      this.studentPercentageText = data.studentPercentageText ?? "";
      this.examTypeToken = data.examTypeToken ?? "";
      this.examTypeNameCurrent = data.examTypeNameCurrent ?? "";
      this.mediaFileTypeToken = data.mediaFileTypeToken ?? "";
      this.mediaFileTypeNameCurrent = data.mediaFileTypeNameCurrent ?? "";
      this.mediaFileName = data.mediaFileName ?? "";
      this.mediaFilePath = data.mediaFilePath ?? "";
      this.mediaFileIsDefault = data.mediaFileIsDefault ?? true;
      this.mediaFileSizeBytes = data.mediaFileSizeBytes ?? 0;
      this.educationalGroupStudentToken =
        data.educationalGroupStudentToken ?? "";
      this.userStudentInfoData.fillDataUserCreatedData(
        data.userStudentInfoData
      );
      this.educationalGroupInfoData.fillData(data.educationalGroupInfoData);
      this.educationalCategoryData.fillData(data.educationalCategoryData);
      this.monitorScoreStudentsDataHandler =
        data.monitorScoreStudentsDataHandler ?? [];
      this.monitorScoreStudentsData = data.monitorScoreStudentsData ?? [];
      this.educationalGroupStudentTokens =
        data.educationalGroupStudentTokens ?? [];
      this.addMany = data.addMany ?? false;
    } else {
      this.setInitialValue();
    }
  }
}
