export default class EducationalGroupUpdateWarning {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.educationalGroupStudentToken = "";
    this.warningStatus1 = "";
    this.warningStatus2 = "";
    this.warningStatus3 = "";
    this.warningStatus4 = "";
    this.warningStatus5 = "";
  }
  fillData(data) {
    if (data) {
      this.educationalGroupStudentToken =
        data.educationalGroupStudentToken ?? "";
      this.warningStatus1 = data.warningStatus1 ?? "";
      this.warningStatus2 = data.warningStatus2 ?? "";
      this.warningStatus3 = data.warningStatus3 ?? "";
      this.warningStatus4 = data.warningStatus4 ?? "";
      this.warningStatus5 = data.warningStatus5 ?? "";
    } else {
      this.setInitialValue();
    }
  }
}
