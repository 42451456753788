<template>
  <div>
    <PreLoader v-if="isLoading" />

    <CustomBottomSheet
      refName="MonitorScoreExamAdd"
      size="xl"
      :headerText="$t('MonitorScoreExams.add')"
      :headerIcon="MonitorScoreExam.icon"
      :clickToClose="false"
      :swipeAble="false"
      @opened="sheetOpened()"
      @closed="opened = false"
    >
      <!-- @opened="MonitorScoreExam.setInitialValue()" -->
      <MonitorScoreExamForm
        v-if="!isLoading"
        id="add"
        :MonitorScoreExam="MonitorScoreExam"
        :opened="opened"
        v-on:submitForm="addMonitorScoreExam()"
        bottomSheetName="MonitorScoreExamAdd"
        :submitName="$t('add')"
      />
    </CustomBottomSheet>
    <ConfirmClearData
      dialogName="MonitorScoreExamAdd"
      v-on:response="response($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "./../../../../components/general/PreLoader.vue";
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
import ConfirmClearData from "./../../../../components/general/ConfirmClearData.vue";
import MonitorScoreExamForm from "./MonitorScoreExamForm.vue";
import { STATUS } from "./../../../../utils/constants";
import { objectToFormData } from "./../../../../utils/functions";
import apiMonitorScoreExam from "./../../../../api/educational/monitorScoreExams";
import generalMixin from "./../../../../utils/generalMixin";

export default {
  mixins: [generalMixin],
  components: {
    PreLoader,
    CustomBottomSheet,
    ConfirmClearData,
    MonitorScoreExamForm,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  props: ["MonitorScoreExam", "monitorScoreStudentsDataHandler"],
  data() {
    return {
      opened: false,
    };
  },
  methods: {
    sheetOpened() {
      this.opened = true;
      this.handelAddData();
    },
    handelAddData() {
      if (!this.MonitorScoreExam.addMany) {
        this.MonitorScoreExam.monitorScoreStudentsData.push({
          educationalGroupStudentToken:
            this.MonitorScoreExam.educationalGroupStudentToken,
          studentScore: this.MonitorScoreExam.studentScore,
        });
      } else {
        this.MonitorScoreExam.setInitialValue(true);

        this.MonitorScoreExam.monitorScoreStudentsDataHandler =
          this.monitorScoreStudentsDataHandler;

        this.MonitorScoreExam.monitorScoreStudentsData =
          this.MonitorScoreExam.monitorScoreStudentsDataHandler.map(
            (element) => {
              return {
                educationalGroupStudentToken:
                  element.educationalGroupStudentToken,
                studentScore: element.studentScore,
              };
            }
          );
      }
    },
    async addMonitorScoreExam() {
      this.isLoading = true;
      if (!this.MonitorScoreExam.addMany) {
        this.MonitorScoreExam.studentScore =
          this.MonitorScoreExam.monitorScoreStudentsDataHandler[0].studentScore;
        let formData = objectToFormData(this.MonitorScoreExam);
        try {
          const response = await apiMonitorScoreExam.add(formData);
          if (response.data.status == STATUS.SUCCESS) {
            this.showMsg(response.data.msg, true);
            // this.$bvModal.show("ConfirmClearData-MonitorScoreExamAdd");
            this.closeBottomSheet("MonitorScoreExamAdd");
          } else if (response.data.status == STATUS.INVALID_TOKEN) {
            this.$store.dispatch("logoutUser", response.data.msg);
            this.showMsg(response.data.msg);
          } else {
            this.showMsg(response.data.msg);
          }
        } catch (error) {
          this.showMsg(this.$t("errorCatch"));
        }
      } else {
        let filteredMonitorScoreStudentsData = [];
        this.MonitorScoreExam.educationalGroupStudentTokens.forEach(
          (element) => {
            let filteredList =
              this.MonitorScoreExam.monitorScoreStudentsDataHandler.filter(
                (i) => i.educationalGroupStudentToken == element
              );
            filteredMonitorScoreStudentsData.push(filteredList[0]);
          }
        );

        let addData = {
          userAuthorizeToken: this.userAuthorizeToken,
          examNameAr: this.MonitorScoreExam.examNameAr,
          examNameEn: this.MonitorScoreExam.examNameEn,
          examNameUnd: this.MonitorScoreExam.examNameUnd,
          examNameNotes: this.MonitorScoreExam.examNameNotes,
          examTypeToken: this.MonitorScoreExam.examTypeToken,
          examFinalScore: this.MonitorScoreExam.examFinalScore,
          monitorScoreStudentsData: filteredMonitorScoreStudentsData.map(
            (i) => {
              return {
                educationalGroupStudentToken: i.educationalGroupStudentToken,
                studentScore: i.studentScore,
              };
            }
          ),
        };

        try {
          const response = await apiMonitorScoreExam.addMany(addData);
          if (response.data.status == STATUS.SUCCESS) {
            this.showMsg(response.data.msg, true);
            // this.$bvModal.show("ConfirmClearData-MonitorScoreExamAdd");
            this.closeBottomSheet("MonitorScoreExamAdd");
          } else if (response.data.status == STATUS.INVALID_TOKEN) {
            this.$store.dispatch("logoutUser", response.data.msg);
            this.showMsg(response.data.msg);
          } else {
            this.showMsg(response.data.msg);
          }
        } catch (error) {
          this.showMsg(this.$t("errorCatch"));
        }
      }
      this.isLoading = false;
    },

    response(data) {
      this.$emit("refresh");
      switch (data) {
        case "yes":
          this.MonitorScoreExam.setInitialValue();
          break;
        case "leave":
          this.MonitorScoreExam.setInitialValue();
          this.closeBottomSheet("MonitorScoreExamAdd");
          break;
      }
    },
  },
};
</script>
