import { METHOD, request } from "../../utils/request";
import { MonitorScoreExams } from "../api";

async function getAll(params) {
  return request(MonitorScoreExams.getAll, METHOD.GET, params);
}
async function getDetails(params) {
  return request(MonitorScoreExams.getDetails, METHOD.GET, params);
}
async function getDialog(params) {
  return request(MonitorScoreExams.getDialog, METHOD.GET, params);
}

async function add(data) {
  return request(MonitorScoreExams.add, METHOD.POST, data);
}
async function addMany(data) {
  return request(MonitorScoreExams.addMany, METHOD.POST, data);
}
async function update(data) {
  return request(MonitorScoreExams.update, METHOD.POST, data);
}
async function finalDelete(data) {
  return request(MonitorScoreExams.finalDelete, METHOD.POST, data);
}
async function deleteImage(data) {
  return request(MonitorScoreExams.deleteImage, METHOD.POST, data);
}

export default {
  getAll,
  getDetails,
  getDialog,
  add,
  addMany,
  update,
  finalDelete,
  deleteImage,
};
