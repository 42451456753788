<template>
  <CustomBottomSheet
    :refName="'StudentStatisticsReport'"
    size="xl"
    :headerText="$t('Reports.studentStatistics')"
    :headerIcon="icon"
    @opened="
      educationalGroupStudents.filterData.setInitialValue();
      setFilter();
    "
  >
    <div class="row">
      <CustomInput
        :className="'col-md-12'"
        :id="`sendTo`"
        :value="educationalGroupStudents.filterData.sendTo"
        v-on:changeValue="educationalGroupStudents.filterData.sendTo = $event"
        :title="$t('Reports.sendTo')"
        :imgName="'email.svg'"
      />

      <!-- parent -->
      <CustomSelectBox
        :className="'col-md-12'"
        :id="`educationalGroupToken`"
        :value="educationalGroupStudents.filterData.educationalGroupToken"
        :options="educationalGroupTokenOptions"
        v-on:changeValue="
          educationalGroupStudents.filterData.educationalGroupToken = $event
        "
        :title="$t('EducationalGroups.select')"
        :imgName="'EducationalGroups.svg'"
      />
      <CustomSelectBox
        :className="'col-md-12'"
        :id="`token`"
        :value="educationalGroupStudents.filterData.token"
        :options="educationalGroupStudentTokenOptions"
        v-on:changeValue="educationalGroupStudents.filterData.token = $event"
        :title="$t('students.select')"
        :imgName="'students.svg'"
      />
    </div>

    <div class="form-actions">
      <div class="icon-submit" @click.prevent="getReport">
        <img
          src="@/assets/images/check-icon.svg"
          :title="$t('Reports.print')"
        />
      </div>
      <div
        @click.prevent="closeBottomSheet('StudentStatisticsReport')"
        class="icon-cancel"
      >
        <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
      </div>
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "@/components/general/CustomBottomSheet.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import generalMixin from "@/utils/generalMixin";
import icon from "@/assets/images/statistics.svg";
import { STATUS } from "@/utils/constants";
import EducationalGroupStudents from "@/models/educational/educationalGroupStudents/EducationalGroupStudents";
import apiEducationalGroupStudent from "@/api//educational/educationalGroupStudents";
import { getLanguage, viewFileFromServer } from "@/utils/functions";
import {
  getEducationalGroupsDialog,
  getEducationalGroupStudentsDialogCustomized,
} from "@/utils/dialogsOfApi";

export default {
  mixins: [generalMixin],
  components: {
    CustomBottomSheet,
    CustomInput,
    CustomSelectBox,
  },

  props: {
    educationalGroupToken: {
      type: String,
      default: "",
    },
    educationalGroupStudentToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      language: getLanguage(),
      educationalGroupStudents: new EducationalGroupStudents(),
      icon,
      educationalGroupTokenOptions: [],
      educationalGroupStudentTokenOptions: [],
    };
  },
  watch: {
    "educationalGroupStudents.filterData.educationalGroupToken": function () {
      this.getEducationalGroupStudentsDialog();
    },
  },
  methods: {
    //#region dialogs
    async getDialogs() {
      this.getEducationalGroupsDialog();
      this.getEducationalGroupStudentsDialog();
    },
    async setFilter() {
      this.educationalGroupStudents.filterData.educationalGroupToken =
        this.educationalGroupToken;
      this.educationalGroupStudents.filterData.token =
        this.educationalGroupStudentToken;
      this.getDialogs();
    },

    async getEducationalGroupsDialog() {
      this.$emit("isLoading", true);
      this.educationalGroupTokenOptions = await getEducationalGroupsDialog();
      this.$emit("isLoading", false);
    },
    async getEducationalGroupStudentsDialog() {
      this.$emit("isLoading", true);
      let params = {
        educationalGroupToken:
          this.educationalGroupStudents.filterData.educationalGroupToken,
      };
      this.educationalGroupStudentTokenOptions =
        await getEducationalGroupStudentsDialogCustomized(params);
      this.$emit("isLoading", false);
    },

    async getReport() {
      this.$emit("isLoading", true);
      let params = {
        token: this.educationalGroupStudents.filterData.token,
        sendTo: this.educationalGroupStudents.filterData.sendTo,
      };
      try {
        const response =
          await apiEducationalGroupStudent.getStudentStatisticsReport(params);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.closeBottomSheet("StudentStatisticsReport");
          viewFileFromServer(response.data.reportPath);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.$emit("isLoading", false);
    },
    async created() {},
  },
};
</script>
